import { useEffect } from 'react';
import { cn } from '@/lib/utils';
import GuestLayout from '@/Layouts/GuestLayout';
import { Head, useForm } from '@inertiajs/react';
import LoginImg from '../../images/log-in.jpg';
import Heading from '@/components/atoms/heading';
import Button from '@/components/atoms/button';
import Checkbox from '@/components/molecules/checkbox';
import Text from '@/components/atoms/text';
import Link from '@/components/atoms/link';
import FormItem from '@/components/molecules/formItem';
import Alert from '@/components/molecules/alert';

export default function Login({ status, resetAlert, canResetPassword }) {
	const { data, setData, post, processing, errors, reset } = useForm({
		email: '',
		password: '',
		remember: false,
	});

	useEffect(() => {
		return () => {
			reset('password');
		};
	}, []);

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			console.log(data);
			post(route('login'));
			document.getElementById('submitBtn').focus();
		}
	};
	const submit = (e) => {
		e.preventDefault();
		post(route('login'));
	};

	return (
		<GuestLayout>
			<Head title="Log in" />

			<div className="flex flex-col justify-between md:flex-row">
				<form onKeyDown={handleKeyDown} onSubmit={submit} className="w-full p-4 md:p-eis-72">
					<div className="flex flex-col justify-between h-full gap-y-14">
						{status && (
							<Text type="p" variant="normal" className="text-green-600">
								{status}
							</Text>
						)}

						<Heading type="h4">Sign in</Heading>

						{resetAlert && (
							<Alert
								type="info"
								title="Reset link sent"
								description="If this email address is already registered, instructions on how to reset your password will be on their way to your inbox. Just click the link in the email to proceed."
							/>
						)}

						<div className="flex flex-col gap-y-6">
							<FormItem
								type="email"
								name="email address"
								label="Email address"
								placeholder="Email address"
								value={data.email}
								onChange={(value) => setData((data) => ({ ...data, email: value }))}
								autoComplete="email"
								meta={{
									msg: errors.email,
									touched: errors.email ? true : false,
								}}
							/>

							<FormItem
								type="password"
								name="password"
								label="Password"
								placeholder="Password"
								value={data.password}
								onChange={(value) => setData((data) => ({ ...data, password: value }))}
								autoComplete="password"
								meta={{
									msg: errors.password,
									touched: errors.password ? true : false,
								}}
							/>

							<div className="flex flex-row flex-wrap items-center justify-between gap-2">
								<FormItem
									name="lgoin_remember"
									type="checkbox"
									label="Stay logged in"
									htmlFor="lgoin_remember"
									validation="required"
									disabled={false}
									value={data.remember}
									onChange={(value) => setData((data) => ({ ...data, remember: value }))}
									hasFormItemTop={false}
								/>

								{canResetPassword && (
									<p className="flex gap-1">
										<Text type="span" variant="bold">
											Forgot your
										</Text>

										<Link variant="dark" isExternal={false} href={route('password.request')} className="font-bold">
											password
										</Link>
									</p>
								)}
							</div>

							<Button variant="primary" fullWidth="fullWidth" disabled={processing}>
								Sign in
							</Button>
						</div>
					</div>
				</form>

				<div
					className={cn(
						'flex w-full md:w-[316px] rounded-b-xl md:rounded-bl-none md:rounded-r-xl',
						// Note: use this div's background colour as an extended part of the image when the inputs show error messages
						'shrink-0 bg-corporateBlueBright itmes-end'
					)}
				>
					<img
						src={LoginImg}
						alt="log in image"
						className={cn(
							'rounded-b-xl md:rounded-bl-none md:rounded-r-xl w-full',
							// Note: for when the inputs show error messages
							'md:object-contain object-bottom'
						)}
					/>
				</div>
			</div>
		</GuestLayout>
	);
}
